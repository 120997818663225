<template>
  <div style="height: 100%;">
    <div style="display: flex;overflow: hidden;height:870px;" class="padHeight">
      <div class="task-details-left" :style="{ width: isshow ? '20px' : '340px' }">
        <div style="display: flex;justify-content: flex-end;font-size: 24px;margin-top: 10px;margin-right: 10px;">
          <i v-if="isshow" class="el-icon-right" @click="back"></i>
          <i v-else class="el-icon-back" @click="back"></i>
        </div>
        <div style="display: flex;margin-top: 38px;margin-left: 31px;margin-bottom: 20px" class="peoplePic">
          <img src="../../assets/image/icon/avatar60.png" alt="">
          <div style="margin-left: 20px">
            <span class="span-name">{{ PObj.Name }}</span>
            <span class="span-text">{{ PObj.Age }}岁</span>
            <span class="span-text">{{ PObj.Sex == 1 ? '男' : '女' }}</span>
            <img src="../../assets/image/icon/boy-sex.png" alt="" v-if="PObj.Sex == '1'">
            <img src="../../assets/image/icon/female-sex.png" alt="" v-else>
            <div>
              <span class="span-text">{{ PObj.Tel }}</span>
            </div>
          </div>
        </div>
        <div class="call-phone">
          <el-button type="primary">一键呼叫</el-button>
        </div>
        <div style="margin-bottom: 16px">
          <el-radio-group v-model="radio1">
            <el-radio-button label="随访信息"></el-radio-button>
            <el-radio-button label="备注信息"></el-radio-button>
          </el-radio-group>
        </div>
        <div v-show="radio1 == '备注信息'" class="show-beizhu">
          <el-input type="textarea" placeholder="填写备注" v-model="textarea" maxlength="300" show-word-limit
            style="width: 300px;font-size: 16px" rows="4" />
          <div style="margin-top: 10px;margin-bottom: 20px">
            <el-button style="width: 300px;background: #3388FF;color: #fff">提交</el-button>
          </div>
          <div>
            <span class="span-task-details">共计9条</span>
          </div>
          <ul style="overflow-y:scroll;overflow-x: hidden;height: 440px">
            <li v-for="item in 6" :key="item">
              <span class="span-text" style="color: #333">备注人:</span>
              <span class="span-text" style="color: #333">李晓霞</span>
              <span class="span-time">2021-08-11 12:12:22</span>
              <div class="bei-zhu-info">
                <div>
                  {{ beizhu | ellipsis }}
                </div>
                <div style="position: absolute;right: 20px;bottom: 0;z-index: 999" v-show="isShow">
                  <el-tooltip placement="bottom" effect="light">
                    <div slot="content">
                      <p style="cursor: pointer"><i class="el-icon-edit-outline"></i>修改备注</p>
                      <el-divider></el-divider>
                      <p style="cursor: pointer"><i class="el-icon-delete"></i>删除备注</p>
                    </div>
                    <i class="el-icon-more" style="cursor: pointer"></i>
                  </el-tooltip>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-show="radio1 == '随访信息'" style="margin-top: 30px" class="show-shufang">
          <!-- <div v-if="list.length == 0" class="no-content">
            <img src="../../assets/image/icon/no-data.png" alt="">
            <p>暂无随访信息</p>
          </div> -->
          <div>
            <ul @scroll="Scroll" style="overflow-y:scroll;overflow-x: hidden;height: 580px">
              <div style="margin-bottom: 20px">
                <p>所属项目:{{ ProjectName }}</p>
              </div>
              <li v-for="(item, index) in list" :key="index" style="position: relative;cursor:pointer;"
                @click="EditQuestionnaire(item, index)">
                <div style="margin-bottom: 10px">
                  <p @click="EditQuestionnaire(item, index)">{{ item.QuestionnaireName }}</p>
                </div>
                <div class="task-content" style="margin-bottom: 20px;" :class="{ active: active == index }">
                  <div>
                    <span>随访时间:</span>
                    <!-- <span>{{item.TriggerTime}}</span> -->
                    <span>{{ item.EndTime ? item.EndTime : item.BeginTime }}</span>
                  </div>
                  <div>
                    <span>随访状态</span>
                    <span>{{ item.StatusName }}</span>
                  </div>
                  <div v-if="item.QuestionnaireConfig?.openInterviewCount">
                    <span>访视次数:</span>
                    <span>{{ item.Frq }}</span>
                  </div>
                  <div style="position: absolute;right: 5%;bottom: 9%;">
                    <el-link :underline="false" @click="DeleteQuestionnaire(item)">
                      <i class="el-icon-delete"></i>
                    </el-link>
                  </div>
                </div>
                <!-- <div style="position: absolute;bottom:0;right:20px;cursor:pointer;">
                   <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                             <i class="el-icon-more-outline" style="font-size: 26px;"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="ViewQuestionnaire(item)">查看问卷</el-dropdown-item>
                          <el-dropdown-item @click.native="EditQuestionnaire(item)">修改问卷</el-dropdown-item>
                          <el-dropdown-item @click.native="DeleteQuestionnaire(item)">删除问卷</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                 </div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="task-details-right">
        <iframe v-if="Url != ''" :src="Url" frameborder="0" width="100%" height="880px" ref="myFrame"
          id="iframe"></iframe>
        <div class="no-content" v-else>
          <img src="../../assets/image/icon/no-content.png" alt="">
          <p>暂无随访信息</p>
        </div>
        <!-- <div class="btn" v-if="list.length>0"><el-button type="danger"  @click="DeleteQuestionnaire">删除问卷</el-button></div> -->
        <video id="videocalss" :src="videoUrl" controls v-show="videoUrl != ''"></video>
      </div>
    </div>
  </div>
</template>

<script>
import derive from "../../api/derive"
import { local } from "../../utils/storage"
export default {
  name: "TaskDetails",
  data() {
    return {
      active: null,
      isShow: false,
      beizhu: "今天是我在备注信息，今天是我在备注信息，今天是我在备注信息，今天是我在备注信息，今天是我在备注信息，" +
        "22222222222222222222今天是我在备注信息......",
      textarea: "",
      radio1: "随访信息",
      Url: "",
      list: [],
      flag: false,
      page: 1,
      total: 0,
      item: {},
      ProjectName: local.get("ProjectName"),
      videoUrl: '',
      isshow: false,
      devicePixelRatio: '',
      Config: {}
    }
  },
  props: ['PObj'],
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 60) {
        return value.slice(0, 60) + "...";
      }
      return value;
    },
  },
  created() {
    this.IsPC();
    window.addEventListener('message', (e) => {
      if (e.data == true) {
        this.RefreshFollowUpRecords()
      }
    })
    this.FollowUpRecords()
  },
  // watch: {
  //   Url: {
  //     deep: true,
  //     handler(oldval,newval) {
  //       if(!newval) return;
  //       this.$nextTick(()=>{
  //         console.log(oldval,newval,'val')
  //         console.log(document.getElementById('iframe'));
  //         document.getElementById('iframe').contentWindow.location.reload(true)
  //       })
  //     }
  //   }
  // },
  methods: {
    IsPC() {
      //   var userAgentInfo = navigator.userAgent; // 包含有关浏览器的信息// 非手机端
      //   var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
      //   var flag = true;
      //   for (var v = 0; v < Agents.length; v++) {
      //     if (userAgentInfo.indexOf(Agents[v]) > 0) {
      //       flag = false;
      //       break;        
      //     }    
      //   }  
      // return flag;
      // let userAgentInfo = navigator.userAgent;
      // let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      // let getArr = Agents.filter(i => userAgentInfo.includes(i));
      // return getArr.length ? true : false;
      this.devicePixelRatio = document.documentElement.clientWidth < 1100;
    },
    back() {
      this.isshow = !this.isshow
    },
    RefreshFollowUpRecords() {
      const parameter = {
        Key: '',
        page: this.page,
        rows: 4,
        ProjectPatientId: this.PObj.Id
      }
      derive.FollowUpRecordsData(parameter).then(res => {
        console.log(res, "刷新随访记录");
        if (res.data.Status == 1) {
          if (res.data.Data.rows.length > 0) {
            this.total = res.data.Data.total
            this.list = res.data.Data.rows
          }
        } else {
          alert(res.data.Message)
        }
      })
    },

    //创建iframe节点
    createIframe(dom, src) {
      //在document中创建iframe
      var iframe = document.createElement("iframe");
      //设置iframe的样式
      iframe.style.width = "100%";
      iframe.style.height = "880px";
      iframe.src = src;
      //把iframe载入到dom以下
      dom.appendChild(iframe);
      return iframe;
    },
    //随访记录
    FollowUpRecords() {
      this.videoUrl = ''
      const parameter = {
        Key: '',
        page: this.page,
        rows: 4,
        ProjectPatientId: this.PObj.Id
      }
      derive.FollowUpRecordsData(parameter).then(res => {
        if (res.data.Status == 1) {
          if (res.data.Data.rows.length > 0) {
            this.total = res.data.Data.total
            this.list = this.list.concat(res.data.Data.rows)
            this.list.forEach(item => {
              if (typeof item.QuestionnaireConfig == 'string') {
                item.QuestionnaireConfig = JSON.parse(item.QuestionnaireConfig)
              }
            })
            if (this.page == 1) {
              if (this.list[0].Status == '08' || this.list[0].Status == '06' || this.list[0].Status == '05') {
                if (this.list[0].VoiceFile != null) {
                  this.videoUrl = this.list[0].VoiceFile
                }
                if (this.list[0].CollectedQuestionnaireId != '' || this.list[0].CollectedQuestionnaireId != null) {
                  this.EditQuestionnaire(this.list[0], 0)
                }
              }
            }
            this.flag = false
          }
        } else {
          alert(res.data.Message)
        }
      })
    },
    // //查看
    // ViewQuestionnaire(item){
    //      if(item.FinishStatus!='00'){
    //        this.Url=''
    //        if(item.FinishStatus=='02'){
    //              this.Url=`${window.global_config.BASE_URL1}/#/ViewModification?cqId=${item.CollectedQuestionnaireId}`
    //        }else{
    //          derive.SeeFollowUpRecordsData().then(res=>{
    //               console.log(res);
    //               if(res.data.Status==1){
    //                 this.Url=`${res.data.Data.Url}/#/PcQuestionnaire?type=2&qid=${item.CollectedQuestionnaireId}`
    //               }else{
    //                 alert(res.data.Message)
    //               }
    //          })
    //        }
    //      }else{
    //        alert("此问卷还未被创建")
    //      }
    // },
    //编辑
    EditQuestionnaire(item, index) {
      if (this.active == index) {
        console.log(item)
        return
      }
      this.active = index
      this.item = item
      this.Url = '';
      //maybe status=05
      if (item.CollectedQuestionnaireId == null || item.CollectedQuestionnaireId == '') {
        this.videoUrl = '';
        return
      }
      if (item.Status == '08') {
        this.Url = `${window.global_config.BASE_URL1}/#/ViewModification?cqId=${item.CollectedQuestionnaireId}&r=${Math.random() * 10}`;
        this.Url = 'about:blank';
        this.videoUrl = item.VoiceFile;

        setTimeout(() => {
          this.Url = `${window.global_config.BASE_URL1}/#/ViewModification?cqId=${item.CollectedQuestionnaireId}&r=${Math.random() * 10}`;
          console.log(this.Url);
        }, 300)
      } else {
        derive.EditFollowUpRecordsData().then(res => {
          console.log(res, 'resrteee=================');
          if (res.data.Status == 1) {
            console.log(res.data.Data.Url);
            console.log(item.CollectedQuestionnaireId);
            // this.Url = `${window.global_config.BASE_URL1}/#/P?type=3&qid=${item.CollectedQuestionnaireId}&r=${new Date().getTime()}`;
            // this.Url = `${window.global_config.BASE_URL1}/#/PcIframe?fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&type=5&qid=${item.CollectedQuestionnaireId}&r=${new Date().getTime()}`;
            this.Url = `${res.data.Data.Url}/#/PcIframe?fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&type=5&qid=${item.CollectedQuestionnaireId}&r=${new Date().getTime()}`;
            this.videoUrl = '';
          } else {
            alert(res.data.Message)
          }
        })
      }
    },
    //删除
    DeleteQuestionnaire(rows) {
      this.$confirm('此操作将永久删除随访信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let parameter = [];
        /**
         * parameter.push(this.item.Id)
         */
        parameter.push(rows.Id);

        //   const parameter={
        //     CollectedQuestionnaireId:this.item.CollectedQuestionnaireId,
        //     Id:this.item.Id,
        //     Channel:this.item.Channel,
        //  }
        derive.DeleteFollowUpRecordsData(parameter).then(res => {
          console.log(res);
          if (res.data.Status == 1) {
            this.Url = ''
            if (this.item.Channel == '03') {
              this.list = this.list.filter(row => {
                return row.Id != this.item.Id
              })
            }
            //  this.FollowUpRecords()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.page = 1;
            this.list = [];
            this.active = 0;
            this.flag = false;
            this.FollowUpRecords();
          } else {
            alert(res.data.Message)
          }
        })

      });
    },
    Scroll(e) {
      // console.log(this.$refs.evabox.scrollTop)
      const ele = e.srcElement ? e.srcElement : e.target
      // 监听滚动到div底部
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 2) {
        if (this.flag == false) {
          this.flag = true
          this.page++
          if (this.list.length < this.total) {
            this.FollowUpRecords()
          } else {
            this.$message({
              message: '已经没有更多数据了',
              type: 'success'
            });
          }
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.active {
  border: 1px solid #3388FF !important;
}

.btn {
  // width: 40px;
  position: absolute;
  bottom: 40px;
  right: 100px;

  ::v-deep .el-button {
    height: 40px;
    background: red !important;
  }
}

#videocalss {
  position: absolute;
  top: 60px;
  right: 100px;
  width: 400px;
  height: 40px;
}

.task-details-left {
  width: 340px;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding-left: 20px;
  box-sizing: border-box;
  transition: 0.3s;

  .call-phone {
    margin-bottom: 30px;

    ::v-deep .el-button {
      width: 299px;
      height: 40px;
      color: #3388FF;
      font-weight: 400;
      background: rgba(57, 120, 231, 0.15);
      border: 1px solid rgba(51, 136, 255, 0.5686274509803921);
      border-radius: 4px;
      margin-left: inherit;
    }
  }

  .span-name {
    font-size: 24px;
    font-weight: 500;
    line-height: 41px;
    color: #585858;
    margin-right: 24px
  }

  .span-text {
    @include common_span
  }

  .span-task-details {
    @include common_span
  }

  .show-beizhu {
    ul {
      width: 300px;

      li {
        width: 300px;
        height: 180px;
        background: #F7F7F7;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 16px 17px 0 8px;
        box-sizing: border-box;

        .span-time {
          @include add-size5($font_size_14);
          font-weight: 400;
          line-height: 24px;
          color: #C7C7C7;
        }

        .bei-zhu-info {
          width: 284px;
          height: 97px;
          position: relative;
          @include add-size($font_size_16);
          font-weight: 400;
          color: #585858;
          margin-top: 20px;
          box-sizing: border-box;
        }
      }
    }
  }

  .show-shufang {
    .no-content {
      width: 288px;
      text-align: center;
      margin: 121px auto 0 auto;

      p {
        width: 288px;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        color: #AAAFB5;
      }
    }

    ul {
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 31px;
        color: #333333;
      }

      li {
        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 31px;
          color: #333333;
        }

        .task-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 300px;
          // height: 120px;
          background: #F7F7F7;
          opacity: 1;
          border-radius: 4px;
          padding: 5px 0 5px 12px;
          box-sizing: border-box;

          &>div {
            padding: 3px 0;
          }

          span {
            @include common_span
          }
        }
      }
    }
  }
}

.task-details-right {
  // width: 1540px;
  flex: 1;
  height: 100%;
  background-color: #ffffff;
  margin-top: 10px;

  .no-content {
    width: 288px;
    text-align: center;
    margin: 30% auto 0 auto;

    p {
      width: 288px;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #AAAFB5;
    }
  }
}

::v-deep .el-radio-button {
  width: 150px;

  .el-radio-button__inner {
    width: 150px;
  }
}

::v-deep .el-divider--horizontal {
  margin: 6px 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  #videocalss {
    width: 120px;
  }

  .padHeight {
    height: 950px !important;

    .peoplePic {
      margin-left: 0 !important;
      margin-top: 15px !important;
    }
  }
}
</style>
