<template>
  <div>
    <div>
      <el-button @click="addpatient" type="primary">+添加标签</el-button>
    </div>
    <div>
      <el-dialog
        :visible.sync="patientVisible"
        width="550px">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">添加标签</span>
        </div>
        <el-row>
          <el-col :span="5">
            <span class="span">新建标签:</span>
          </el-col>
          <el-col :span="19" >
            <el-input v-model="PatientLabel" placeholder="请输入" style="width: 360px;height: 40px"></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:10px;margin-bottom: 30px">
          <el-col :span="5">
                  <p style="height:50px;"></p>
          </el-col>
          <el-col :span="19" >
            <div class="submit-btn">
              <el-button @click="onsumbit" type="primary" style="margin-left: 0px">确定</el-button>
              <el-button @click="patientVisible = false" class="plan">取消</el-button>
              <el-button @click="reset" class="plan">重置</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <span class="span">已有标签:</span>
          </el-col>
          <el-col :span="19" style="max-height:200px;height:100px;overflow-y: auto;">
            <div>
              <el-tag closable v-for="(item,index) in taglist" :key="index" @close="tagclose(item)" style="margin-right: 10px;margin-bottom: 10px;">
                {{item.Name}}
              </el-tag>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import apiPatientCenter from "../../api/PatientCenter"
export default {
  name: "AddLabel",
  data() {
    return {
      PatientLabel: "",
      patientVisible: false,
      taglist: [], // 已有标签
      // patientId:"",
    }
  },
  props:['ProjectPatientId'],
  created(){
  },
  methods: {
    addpatient() {
      console.log(this.ProjectPatientId,"ProjectPatientId");
      if(this.ProjectPatientId==''||this.ProjectPatientId==null){
        alert("请先选择一个患者,在点击添加标签")
      }else{
        this.patientVisible = true
        this.GetListProjectLabelPatient()
      }
    },
    tagclose(item) { // 删除标签
     const parameter=[]
     parameter.push(item.Id)
      apiPatientCenter.DeleteProjectLabelPatientData(parameter).then(res=>{
                if(res.data.Status==1){
                    this.taglist=this.taglist.filter(row=>{
                       return row.Id!=item.Id
                    })
                    this.$emit('DelLabelFn',item)
                }else{
                  alert(res.data.Message)
                }
               console.log(res);
      })
    },
    GetListProjectLabelPatient(){
            const parameter={
              ProjectPatientId:this.ProjectPatientId.Id ? this.ProjectPatientId.Id : this.ProjectPatientId,
            }
            apiPatientCenter.GetListProjectLabelPatientData(parameter).then(res=>{
                        if(res.data.Status==1){
                           this.taglist=res.data.Data
                        }else{
                          alert(res.data.Message)
                        }
                 console.log(res,"###");
            })
    },
    //点击确定
    onsumbit() {
          const parameter={
            ProjectPatientId:this.ProjectPatientId.Id ? this.ProjectPatientId.Id : this.ProjectPatientId,
            Name:this.PatientLabel,
          }
          apiPatientCenter.AddProjectLabelPatientData(parameter).then(res=>{
              if(res.data.Status==1){
                  this.taglist.push(res.data.Data)
                  this.$emit('AddLabelFn',res.data.Data);
                  this.patientVisible = false;
                  this.PatientLabel = '';
              }else{
                alert(res.data.Message)
              }
              console.log(res);
          })
    },
    //点击重置
    reset(){
      this.PatientLabel = '';
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";
::v-deep .el-dialog {
  .span {
    margin-left: 20px;
    @include common_span
  }
  .el-input__inner {
    width: 360px;
    height: 40px;
    @include add-size($font_size_16);
  }
  .submit-btn {
    button.plan {
      @include common_button_plain;
      margin-left: 20px;
    }
  }
}
</style>
