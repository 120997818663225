<template>
  <div>
    <el-button @click="AddPatient" type="primary" v-if="type == 1">+新增患者</el-button>
    <!-- <el-link @click="AddPatient" type="primary" :underline="false" v-if="type == 2"><i class="el-icon-edit-outline"></i>修改</el-link> -->
    <div class="add_dialog">
      <el-dialog :visible.sync="AddPatientDialog" width="1121px" top="1vh" v-dialogDrag @close="Patientcancel"
                 @open="handleOpen" custom-class="customWidth" :destroy-on-close="true">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age" v-if="type == 1">新增患者</span>
          <span class="title-age" v-if="type == 2">修改信息</span>
        </div>
        <!--        {{ ProjectId }}-->
        <el-form v-if="showAll" label-width="170px" :model="formLabelAlign" ref="formLabelAlign" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属项目:">
                <el-cascader
                  v-model="ProjectId"
                  :options="BelongingProject"
                  :props="{ multiple: true, checkStrictly: true, value: 'Id', label: 'Name'}"
                  clearable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="医保卡号:">
                <el-input v-model="formLabelAlign.MiCardNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="患者编号:">
                <el-input v-model="formLabelAlign.No"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="就诊日期:">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="formLabelAlign.VisitDate"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="患者姓名:">
                <el-input v-model="formLabelAlign.Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手术日期:">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="formLabelAlign.OperationDate"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="姓名缩写:">
                <el-input v-model="formLabelAlign.NameAbbr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="风险等级:">
                <el-select placeholder="请选择" v-model="formLabelAlign.RiskRating">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in RiskLevel"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="患者电话:">
                <el-input maxlength="11" v-model="formLabelAlign.Tel" style="width: 360px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="住院病区:">
                <el-input v-model="formLabelAlign.Ward"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="微信:">
                <el-input v-model="formLabelAlign.WX"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="住院天数:">
                <el-input v-model="formLabelAlign.Day"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="证件类型:">
                <el-select placeholder="请选择" v-model="formLabelAlign.CertType">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in DocumentType"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出院日期:">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="formLabelAlign.DischargeDate"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="证件号码:">
                <el-input maxlength="18" v-model="formLabelAlign.CertNo" @change="CertNoChange"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出院诊断:">
                <el-input v-model="formLabelAlign.DischargeDiagnosis"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="出生日期:">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="formLabelAlign.Birthday"
                                @change="getAge(formLabelAlign.Birthday)" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="次要诊断:">
                <el-input v-model="formLabelAlign.SecondaryDiagnosis"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="年龄:">
                <el-input v-model="formLabelAlign.Age" style="width: 330px"></el-input>
                <span>岁</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出院方式:">
                <el-select placeholder="请选择" v-model="formLabelAlign.DischargeMode">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in DischargeMode"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别:">
                <el-radio-group v-model="formLabelAlign.Sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="主治医生:">
                <el-input v-model="formLabelAlign.AttendingDoctor"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="身高:">
                <el-input v-model="formLabelAlign.Height" style="width: 330px"></el-input>
                <span>m</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="随访医生:">
                <el-input v-model="formLabelAlign.FollowUpDoctor"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="体重:">
                <el-input v-model="formLabelAlign.Weight" style="width: 330px"></el-input>
                <span>kg</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="教育程度">
                <el-select placeholder="请选择" v-model="formLabelAlign.Edu">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in Educationallevel"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="患者QQ:">
                <el-input v-model="formLabelAlign.QQ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="随机号:">
                <el-input v-model="formLabelAlign.RandomNumber" :disabled="IsRtc == false"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="紧急联系人姓名:">
                <el-input v-model="formLabelAlign.EcName" style="width: 360px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="组别号:">
                <el-input v-model="formLabelAlign.GroupNumber" :disabled="IsRtc == false"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="紧急联系人电话">
                <el-input v-model="formLabelAlign.EcTel" style="width: 360px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="患者来源:">
                <el-select placeholder="请选择" v-model="formLabelAlign.Source">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in PatientSource"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"></el-col>
            <el-col :span="12">
              <el-form-item label="患者地址:">
                <el-input v-model="formLabelAlign.Address"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form v-else label-width="170px" :model="formLabelAlign" ref="formLabelAlign" :rules="rules2"
                 class="gridForm">
          <el-form-item label="所属项目:" :rules="{
                required: true, message: '此项为必填项'
              }">
            <el-cascader v-if="type == 1"
                         v-model="ProjectId"
                         :options="BelongingProject"
                         :props="{ multiple: true, checkStrictly: true, value: 'Id', label: 'Name'}"
                         clearable></el-cascader>
            <el-select v-else v-model="formLabelAlign.ProjectId" placeholder="请选择" :disabled="flag">
              <el-option disabled v-for="(item, index) in BelongingProject" :key="index" :label="item.Name"
                         :value="item.Id">
              </el-option>
            </el-select>
          </el-form-item>
          <template v-for="item in formItemList">
            <div v-if="item.IsEnable && bindEnum[item.FieldName]">
              <!-- 文本框 -->
              <el-form-item :label="bindEnum[item.FieldName] + ':'" :prop="item.IsRequired ? item.FieldName : ''"
                            :rules="{
                required: item.IsRequired, message: '此项为必填项'
              }"
                            v-if="['AdmissionDiagnosis', 'No', 'Name', 'NameAbbr', 'Tel', 'WX', 'CertNo', 'Age', 'EcTel', 'EcName', 'QQ', 'Weight', 'Height', 'Address', 'GroupNumber', 'RandomNumber', 'FollowUpDoctor', 'AttendingDoctor', 'SecondaryDiagnosis', 'DischargeDiagnosis', 'Day', 'Ward', 'MiCardNo'].includes(item.FieldName)">
                <el-input v-model="formLabelAlign[item.FieldName]"
                          @change="handleChange(item.FieldName, formLabelAlign[item.FieldName])"></el-input>
              </el-form-item>
              <!-- 下拉框 -->
              <el-form-item :label="bindEnum[item.FieldName] + ':'" :prop="item.IsRequired ? item.FieldName : ''"
                            :rules="{
                required: item.IsRequired, message: '此项为必填项'
              }"
                            v-else-if="['Source', 'Edu', 'DischargeMode', 'RiskRating', 'CertType', 'GroupType'].includes(item.FieldName)">
                <el-select v-model="formLabelAlign[item.FieldName]" placeholder="请选择" :disabled="flag">
                  <!-- 证件类型 -->
                  <template v-if="item.FieldName === 'CertType'">
                    <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in DocumentType"
                               :key="index"></el-option>
                  </template>
                  <!-- 风险等级 -->
                  <template v-else-if="item.FieldName === 'RiskRating'">
                    <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in RiskLevel"
                               :key="index"></el-option>
                  </template>
                  <!-- 出院方式 -->
                  <template v-else-if="item.FieldName === 'DischargeMode'">
                    <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in DischargeMode"
                               :key="index"></el-option>
                  </template>
                  <!-- 教育程度 -->
                  <template v-else-if="item.FieldName === 'Edu'">
                    <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in Educationallevel"
                               :key="index"></el-option>
                  </template>
                  <template v-else-if="item.FieldName === 'Source'">
                    <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in PatientSource"
                               :key="index"></el-option>
                  </template>
                  <template v-else-if="item.FieldName === 'GroupType'">
                    <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in Group"
                               :key="index"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <!-- 单选 -->
              <el-form-item label="性别:" v-else-if="item.FieldName === 'Sex'">
                <el-radio-group v-model="formLabelAlign.Sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 日期 -->
              <el-form-item :label="bindEnum[item.FieldName] + ':'" :prop="item.IsRequired ? item.FieldName : ''"
                            :rules="{
                required: item.IsRequired, message: '此项为必填项'
              }"
                            v-else-if="['DischargeDate', 'OperationDate', 'VisitDate', 'Birthday', 'AdmissionDate'].includes(item.FieldName)">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="formLabelAlign[item.FieldName]"
                                value-format="yyyy-MM-dd" @change="handleDatePickerChange(item)">
                </el-date-picker>
              </el-form-item>
            </div>
          </template>
        </el-form>
        <div class="btn">
          <button @click="Patientcancel">取消</button>
          <button @click="PatientSubmit">提交</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import PatientCenter from "../../api/PatientCenter"
import ProjectManagement from "../../api/ProjectManagement"
import derive from "../../api/derive"
import {validatePhone, validateregId} from "../../utils/verification"
import {local} from "../../utils/storage"

let addFormData = {
  No: null,
  MiCardNo: null,
  Name: null,
  NameAbbr: null,
  Sex: null,
  Birthday: null,
  Age: null,
  Tel: null,
  Height: null,
  Weight: null,
  WX: null,
  QQ: null,
  CertType: '00',
  CertNo: null,
  Edu: null,
  EcName: null,
  EcTel: null,
  VisitDate: null,
  RiskRating: '01',
  Ward: null,
  Day: null,
  DischargeDate: null,
  DischargeDiagnosis: null,
  DischargeMode: null,
  FollowUpDoctor: null,
  RandomNumber: null,
  GroupNumber: null,
  Source: null,
  Address: null,
  ProjectUnitId: null,
  OperationDate: null,
  SecondaryDiagnosis: null,
  AdmissionDiagnosis: null,
  GroupType: ""
}
export default {
  data() {
    return {
      flag: false,
      Name: [
        {required: true},
      ],
      Tel: [
        {required: true},
      ],
      No: [
        {required: true},
      ],
      AddPatientDialog: false,
      // formLabelAlign: JSON.parse(JSON.stringify(addFormData)),
      formLabelAlign: JSON.parse(JSON.stringify(addFormData)),
      ProjectId: [],
      RiskLevel: [],//风险等级
      DocumentType: [],//证件类型
      Educationallevel: [],//教育程度
      PatientSource: [],//患者来源
      DischargeMode: [],//出院方式
      Group: [],//组别
      BelongingProject: [],//所属项目
      Company: [],//单位
      rules: {
        Tel: [
          {validator: validatePhone, trigger: 'blur', required: true}
        ],
        CertNo: [
          {validator: validateregId, trigger: 'blur'}
        ],
        Name: [
          {required: true, message: '患者姓名不能为空!', trigger: 'blur'}
        ],
        No: [
          {required: true, message: '患者编号不能为空!', trigger: 'blur'}
        ],
      },
      rules2: {r: {required: true, message: '此项为必填项'},},
      IsRtc: local.get('IsRtc'),
      //判断电话号码和身份证是否改变
      TelIsChange: '',
      CertNoIsChange: '',
      formItemList: [],
      bindEnum: {
        MiCardNo: '医保卡号',
        No: '患者编号',
        VisitDate: '就诊日期',
        Name: '患者姓名',
        OperationDate: '手术日期',
        NameAbbr: '姓名缩写',
        RiskRating: '风险等级',
        Tel: '患者电话',
        Ward: '住院病区',
        WX: '微信',
        Day: '住院天数',
        CertType: '证件类型',
        DischargeDate: '出院日期',
        CertNo: '证件号码',
        DischargeDiagnosis: '出院诊断',
        Birthday: '出生日期',
        SecondaryDiagnosis: '次要诊断',
        Age: '年龄(岁)',
        DischargeMode: '出院方式',
        Sex: '性别',
        AttendingDoctor: '主治医生',
        Height: '身高(m/米)',
        FollowUpDoctor: '随访医生',
        Weight: '体重(kg/千克)',
        Edu: '教育程度',
        QQ: '患者QQ',
        RandomNumber: '随机号',
        EcName: '紧急联系人姓名',
        GroupNumber: '组别号',
        EcTel: '紧急联系人电话',
        Source: '患者来源',
        Address: '患者地址',
        AdmissionDiagnosis: '入院诊断',
        AdmissionDate: '入院时间',
        GroupType: '组别',
      },
      showAll: true,
    }
  },
  props: ["type", "item", "project", "dialog"],
  created() {
    if (this.dialog) {
      PatientCenter.GetOrder({type: 'P'}).then(res => {
        res.data.Data.length === 0 ? this.showAll = true : this.showAll = false
        this.formItemList = res.data.Data
      })
    }
    // alert(this.IsRtc)
    // if(this.project!='all'){
    //   console.log(this.project,"$$$$");
    //    this.formLabelAlign.ProjectId=this.project
    // }
    // if(this.$route.query.Id!=null){
    //   this.flag=true
    //    this.formLabelAlign.ProjectId=this.$route.query.Id
    // }
    if (this.item != null) {
      this.BasicData();
      this.AddPatientDialog = this.dialog;
      this.formLabelAlign = JSON.parse(JSON.stringify(this.item));
      this.TelIsChange = this.item.Tel;
      this.CertNoIsChange = this.item.CertNo;
    }
  },
  methods: {
    // TelChang(e){
    //      validatePhone(e)
    // },
    //根据身份证获取出生日期,年龄,性别
    CertNoChange(e) {
      this.formLabelAlign.Birthday = this.IdCard(e, 1)
      this.formLabelAlign.Age = this.IdCard(e, 3)
      this.formLabelAlign.Sex = this.IdCard(e, 2)
    },
    IdCard(IdCard, type) {
      if (type === 1) {
        //获取出生日期
        let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
        return birthday
      }
      if (type === 2) {
        //获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
          return "1"
        } else {
          return "0"
        }
      }
      if (type === 3) {
        //获取年龄
        var ageDate = new Date()
        var month = ageDate.getMonth() + 1
        var day = ageDate.getDate()
        var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
          age++
        }
        if (age <= 0) {
          age = 1
        }
        return age
      }
    },
    getAge(date) {
      console.log("date -->", date)
      var ageDate = new Date()
      var month = ageDate.getMonth() + 1
      var day = ageDate.getDate()
      var age = ageDate.getFullYear() - date.substring(0, 4) - 1
      if (date.substring(5, 7) < month || date.substring(5, 7) === month && date.substring(8, 10) <= day) {
        age++
      }
      if (age <= 0) {
        age = 1
      }
      this.formLabelAlign.Age = age
    },
    handleChange(FieldName, value) {
      // 身份证自动获取性别,出生日期,年龄
      if (FieldName === 'CertNo') {
        this.formLabelAlign.Birthday = this.IdCard(value, 1)
        this.formLabelAlign.Age = this.IdCard(value, 3)
        this.formLabelAlign.Sex = this.IdCard(value, 2)
      }
    },
    Patientcancel() {
      // this.$nextTick(()=>{
      //   this.$refs[formName].resetFields();
      // })
      this.formLabelAlign = {};
      this.$store.commit("dialogFlagFn", false);
      this.AddPatientDialog = false;
    },
    //打开患者中心新增患者弹框
    async AddPatient() {
      let res = await PatientCenter.GetOrder({type: 'P'})
      res.data.Data.length === 0 ? this.showAll = true : this.showAll = false
      this.formItemList = res.data.Data
      this.IsRtc = local.get('IsRtc')
      this.AddPatientDialog = true
      //  this.formLabelAlign={}
      this.formLabelAlign = {
        // ProjectId: ['6bae7895-f101-4daf-987d-4790f62693da'],
        ProjectId: null,
        No: null,
        MiCardNo: null,
        Name: null,
        NameAbbr: null,
        Sex: null,
        Birthday: null,
        Age: null,
        Tel: null,
        Height: null,
        Weight: null,
        WX: null,
        QQ: null,
        CertType: '00',
        CertNo: null,
        Edu: null,
        EcName: null,
        EcTel: null,
        VisitDate: null,
        RiskRating: '01',
        Ward: null,
        Day: null,
        DischargeDate: null,
        DischargeDiagnosis: null,
        DischargeMode: null,
        FollowUpDoctor: null,
        RandomNumber: null,
        GroupNumber: null,
        Source: null,
        Address: null,
        ProjectUnitId: null,
        OperationDate: null,
        SecondaryDiagnosis: null
      }
      if (this.$route.query.Id != null) {
        // this.flag = true
        this.formLabelAlign.ProjectId = this.$route.query.Id
      }
      this.BasicData()
      this.$refs.formLabelAlign?.resetFields()
    },
    //提交前的表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },

    Vaild() {
      ProjectManagement.EditProjectPatientData(this.formLabelAlign).then(res => {
        if (res.data.Status == 1) {
          console.log(res.data.Data, 'resData')
          this.$emit("EditSonToFather", res.data.Data)
          this.$message({
            message: res.data.Message,
            type: 'success'
          });
          this.AddPatientDialog = false
        } else {
          alert(res.data.Message)
        }
        console.log(res, "修改项目患者");
      })
    },
    // 提取ProjectId数组最后一项 拿到选择的一项
    flatProjectId(ProjectId) {
      let arr = []
      ProjectId.forEach(item => {
        arr.push(item.slice(-1)[0])
      })
      return arr
    },
    // 回显
    covertProjectId(ProjectId) {

    },
    //新增患者
    PatientSubmit() {

      if (this.type == 1) {
        if (this.ProjectId.length === 0) {
          this.$message({
            showClose: true,
            message: '所属项目、患者姓名为必填项。患者编号和患者电话至少填写一项',
            type: 'warning',
            duration: 0
          });
          return
        }

        // if(this.formLabelAlign.Name==null&&this.formLabelAlign.Tel==null){
        //   alert("姓名和电话为必填项")
        //   return
        // };
        if (this.formLabelAlign.Name == null) {
          this.$message({
            showClose: true,
            message: '所属项目、患者姓名为必填项。患者编号和患者电话至少填写一项',
            type: 'warning',
            duration: 0
          });
          return
        }

        if (!this.formLabelAlign.No && !this.formLabelAlign.Tel) {
          // alert("所属项目、所属姓名为必填项。患者编号和患者电话至少填写一项")
          this.$message({
            showClose: true,
            message: '所属项目、患者姓名为必填项。患者编号和患者电话至少填写一项',
            type: 'warning',
            duration: 0
          });
          return
        }

        // 身高体重判断
        let {Weight} = this.formLabelAlign
        let {Height} = this.formLabelAlign
        let BoolWeight = false
        if (Weight <= 0 || Weight >= 500 || Weight == null) {
          BoolWeight = true
        }
        let BoolHeight = false
        if (Height <= 0 || Height >= 3 || Height == null) {
          BoolHeight = true
        }
        if (Boolean(Height) && Boolean(Weight) && BoolWeight && BoolHeight) {
          this.$message({
            showClose: true,
            message: '请填写正确的身高和体重，注意此处单位是米和千克！',
            type: 'warning',
            duration: 0
          });
          return
        }
        if (Boolean(Weight) && BoolWeight) {
          this.$message({
            showClose: true,
            message: '请填写正确的体重，注意此处单位是千克！',
            type: 'warning',
            duration: 0
          });
          return
        }
        if (Boolean(Height) && BoolHeight) {
          this.$message({
            showClose: true,
            message: '请填写正确的身高，注意此处单位是米',
            type: 'warning',
            duration: 0
          });
          return
        }
        if (this.formLabelAlign.Tel) {
          var reg_tel = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
          if (!reg_tel.test(this.formLabelAlign.Tel)) {
            // alert("请输入正确手机号");
            this.$message({
              showClose: true,
              message: '请输入正确的手机号码',
              type: 'warning',
              duration: 0
            });
            return
          }
          ;
        }
        if (this.formLabelAlign.No) {
          var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
          if (reg.test(this.formLabelAlign.No)) {
            this.$message({
              showClose: true,
              message: '患者编号中不能含有中文',
              type: 'warning',
              duration: 0
            });
            return;
          }
        }

        this.$refs.formLabelAlign.validate(v => {
          if (!v) return
          let params = {
            projectIds: this.flatProjectId(this.ProjectId),
            projectPatient: {...this.formLabelAlign}
          }
          ProjectManagement.AddProjectPatientData(params).then(res => {
            if (res.data.Status == 1) {
              this.$emit("AddSonToFather", res.data.Data)
              this.$message({
                message: res.data.Message,
                type: 'success'
              });
              this.AddPatientDialog = false
            } else {
              alert(res.data.Message)
            }
            console.log(res, "新增项目患者");
          })
        })

        return
      }
      if (this.type == 2) {
        if (!this.formLabelAlign.No && !this.formLabelAlign.Tel) {
          alert("患者编号和患者电话至少填写一项！")
          return
        }

        // 身高体重判断
        let {Weight} = this.formLabelAlign
        let {Height} = this.formLabelAlign
        let BoolWeight = false
        if (Weight <= 0 || Weight >= 500 || Weight == null) {
          BoolWeight = true
        }
        let BoolHeight = false
        if (Height <= 0 || Height >= 3 || Height == null) {
          BoolHeight = true
        }
        if (Boolean(Height) && Boolean(Weight) && BoolWeight && BoolHeight) {
          this.$message({
            showClose: true,
            message: '请填写正确的身高和体重，注意此处单位是米和千克',
            type: 'warning',
            duration: 0
          });
          return
        }
        if (Boolean(Weight) && BoolWeight) {
          this.$message({
            showClose: true,
            message: '请填写正确的体重，注意此处的单位是千克！',
            type: 'warning',
            duration: 0
          });
          return
        }
        if (Boolean(Height) && BoolHeight) {
          this.$message({
            showClose: true,
            message: '请填写正确的身高，注意此处单位是米',
            type: 'warning',
            duration: 0
          });
          return
        }
        this.Vaild();

        //提交前的校验
        // if(this.formLabelAlign.Tel != this.TelIsChange && this.formLabelAlign.CertNo == this.CertNoIsChange){
        //   this.$refs.formLabelAlign.validateField('Tel',(valid)=>{
        //     if(!valid){
        //       this.formLabelAlign.CertNo = '';
        //       this.Vaild();
        //     }
        //   })
        // }else if(this.formLabelAlign.Tel == this.TelIsChange && this.formLabelAlign.CertNo != this.CertNoIsChange){
        //   this.$refs.formLabelAlign.validateField('CertNo',(valid)=>{
        //     if(!valid){
        //       this.formLabelAlign.Tel = '';
        //       this.Vaild();
        //     }
        // })
        // }else if(this.formLabelAlign.Tel == this.TelIsChange && this.formLabelAlign.CertNo == this.CertNoIsChange){
        //   this.formLabelAlign.Tel = '';
        //   this.formLabelAlign.CertNo = '';
        //   this.Vaild();
        // }else{
        //   this.$refs.formLabelAlign.validateField('CertNo',(valid)=>{
        //     if(!valid){
        //       this.$refs.formLabelAlign.validateField('Tel',(valid1)=>{
        //         if(!valid1){
        //           this.Vaild();
        //       }
        //     })
        //     }
        //   })
        // };
        // this.TelIsChange = '';
        // this.CertNoIsChange= '';


        // this.$refs.formLabelAlign.validate((valid) => {
        //     if(valid){
        //       ProjectManagement.EditProjectPatientData(this.formLabelAlign).then(res=>{
        //         if(res.data.Status==1){
        //           this.$emit("EditSonToFather",this.formLabelAlign)
        //           this.$message({
        //             message: res.data.Message,
        //             type: 'success'
        //           });
        //           this.AddPatientDialog=false
        //         }else{
        //           alert(res.data.Message)
        //         }
        //           console.log(res,"修改项目患者");
        //       });
        //     } else {
        //       this.$message({
        //         message:'还有内容未填写完成',
        //         type:'warning'
        //       })
        //       return false;
        //     }
        // })


        // ProjectManagement.EditProjectPatientData(this.formLabelAlign).then(res=>{
        //       if(res.data.Status==1){
        //         this.$emit("EditSonToFather",this.formLabelAlign)
        //         this.$message({
        //           message: res.data.Message,
        //           type: 'success'
        //         });
        //         this.AddPatientDialog=false
        //      }else{
        //        alert(res.data.Message)
        //      }
        //         console.log(res,"修改项目患者");
        // })
        // return
      }
    },
    convertToTree(flatArray) {
      const map = {};
      const tree = [];

      // 构建映射
      flatArray.forEach(item => {
        if (!item.IsLeaf || item.Enable === false) {
          this.$set(item, 'disabled', true)
        }
        map[item.Id] = {...item, children: []};
      });

      // 构建树形结构
      flatArray.forEach(item => {
        if (item.ParentId !== null && map[item.ParentId]) {
          map[item.ParentId].children.push(map[item.Id]);
        } else if (item.ParentId === null) {
          tree.push(map[item.Id]);
        }
      });

      return tree;
    },
    BasicData() {
      //所属项目
      derive.ProjectListData().then(res => {
        if (res.data.Status == 1) {
          console.log("res.data.Data -->", res.data.Data)
          this.BelongingProject = this.convertToTree(res.data.Data)
          // if (this.BelongingProject.length === 1) {
          //   this.formLabelAlign.ProjectId = this.BelongingProject[0].Id
          // }
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
      //风险等级
      const RiskLevel = "FXDJ"
      PatientCenter.BaseDataBigData(RiskLevel).then(res => {
        if (res.data.Status == 1) {
          this.RiskLevel = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, '风险等级');
      })
      //证件类型
      const DocumentType = "ZJLX"
      PatientCenter.BaseDataBigData(DocumentType).then(res => {
        if (res.data.Status == 1) {
          this.DocumentType = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, '证件类型');
      })
      //教育程度
      const Educationallevel = "JYCD"
      PatientCenter.BaseDataBigData(Educationallevel).then(res => {
        if (res.data.Status == 1) {
          this.Educationallevel = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, '教育程度');
      })
      //患者来源
      const PatientSource = "HZLY"
      PatientCenter.BaseDataBigData(PatientSource).then(res => {
        if (res.data.Status == 1) {
          this.PatientSource = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, '患者来源');
      })
      //出院方式
      const DischargeMode = "CYFS"
      PatientCenter.BaseDataBigData(DischargeMode).then(res => {
        if (res.data.Status == 1) {
          this.DischargeMode = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, '出院方式');
      })
      // 组别
      const Group = "ZL"
      PatientCenter.BaseDataBigData(Group).then(res => {
        if (res.data.Status == 1) {
          this.Group = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, '组别');
      })
    },
    handleOpen() {
    },
    handleDatePickerChange(item) {
      if (item.FieldName == "AdmissionDate" || item.FieldName == "DischargeDate") {
        if (this.formLabelAlign.AdmissionDate && this.formLabelAlign.DischargeDate) {
          let res = this.calculateDays(this.formLabelAlign.AdmissionDate, this.formLabelAlign.DischargeDate)
          this.formLabelAlign.Day = res
        }
      }
      // 出生日期自动计算年龄
      if (item.FieldName == "Birthday") {
        this.getAge(this.formLabelAlign.Birthday)
      }
    },
    calculateDays(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      // 计算两个日期之间的毫秒数差值
      const timeDiff = Math.abs(end - start);

      // 将毫秒数转换为天数
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      return days;
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
::v-deep .el-col {
  height: 50px;
}

.add_dialog {
  ::v-deep .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }

    .el-dialog__header {
      margin-bottom: 0;
    }

    ::v-deep .el-form {
      .el-input {
        width: 360px;
      }

      .el-input__inner {
        width: 360px;
      }

      .el-select {
        .el-input__inner {
          width: 360px;
        }
      }
    }

    .btn {
      margin-top: 0;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {

  ::v-deep .el-dialog {
    width: 96% !important;

    .el-form-item {
      .el-form-item__label {
        width: 160px !important;
      }

      .el-form-item__content {
        margin-left: 160px !important;

        .el-input__inner {
          width: 145px !important;
        }

        .el-input {
          width: 145px !important;
        }
      }
    }


  }

}

.gridForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
