<template>
  <!--    (data)=>this.$emit('change', data)-->
  <!--  <div>-->
  <!--    {{ cascaderVal }}-->
  <el-cascader
      v-model="cascaderVal" :options="treeOptions" @change="change"
      :props="{ multiple, checkStrictly: true, value: 'Id', label: 'Name'}"
      ref="cascadeRef"
      clearable
  ></el-cascader>
  <!--  </div>-->
</template>

<script>
export default {
  name: "Cascader",
  props: ['value', 'options', 'multiple', 'isDisabled'],
  data() {
    return {
      active: false,
      cascaderVal: [],
      diffVal: ''
    }
  },
  computed: {
    treeOptions() {
      return this.convertToTree(this.options)
    },
  },
  watch: {
    cascaderVal(val, oldVal) {
      if (this.multiple) {
        val = this.flatProjectId(val)
        oldVal = this.flatProjectId(oldVal)
        this.diffVal = this.findDifferentElements(val, oldVal)
      }
    },
    value: {
      handler(val) {
        this.cascaderVal = JSON.parse(JSON.stringify(val))
      },
      immediate: true
    }
  },
  methods: {
    convertToTree(flatArray) {
      const map = {};
      const tree = [];

      // 构建映射
      flatArray.forEach(item => {
        map[item.Id] = {...item, children: []};
      });

      // 构建树形结构
      flatArray.forEach(item => {
        if (this.isDisabled) {
          if (item.Name !== '全部' && item.Name !== '其他') {
            if (!item.IsLeaf || item.Enable === false) {
              this.$set(item, 'disabled', true)
            }
          }
        }

        if (item.ParentId !== null && map[item.ParentId]) {
          map[item.ParentId].children.push(map[item.Id]);
        } else if (item.ParentId === null) {
          tree.push(map[item.Id]);
        }
      });

      return tree;
    },
    // 提取ProjectId数组最后一项 拿到选择的一项
    flatProjectId(ProjectId) {
      let arr = []
      ProjectId.forEach(item => {
        arr.push(item.slice(-1)[0])
      })
      return arr
    },
    // 提取不同项
    findDifferentElements(newValue, oldValue) {
      return newValue.find(item => !oldValue.includes(item))
    },
    change(data) {
      if (!this.multiple) {
        this.$emit('MyChange', data) //更新
        this.$emit('update:value', data)
        this.$refs.cascadeRef.dropDownVisible = false
        return
      }
      setTimeout(() => {
        // data = this.flatProjectId(data)
        // console.log(this.diffVal)
        if (this.diffVal === 'ALL' || this.diffVal === 'OTHER') {
          this.cascaderVal = [[this.diffVal]]
          this.$emit('update:value', [[this.diffVal]])
          this.$emit('MyChange', [[this.diffVal]]) //更新
        } else {
          // 选中的是项目，需要清除全部和其他
          let arr = data.map(item => {
            if (!['ALL', 'OTHER'].includes(item[0])) {
              return item
            }
          }).filter(item => item !== undefined)
          console.log("----------------------", arr)
          this.cascaderVal = arr
          this.$emit('update:value', arr)
          this.$emit('MyChange', arr) //更新
        }
      }, 100)
    }
  },
}
</script>

<style scoped lang="scss">
.el-cascader {
  //width: 200px;
}

//:deep(){
//  .el-cascader__tags{
//    flex-wrap: nowrap;
//    overflow: auto;
//  }
//}
</style>
